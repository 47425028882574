import "../styles/socialLinks.css";
 
import { ReactComponent as GithubIcon } from '../svgs/github-square-svgrepo-com.svg'; 
import { ReactComponent as LinkedInIcon } from '../svgs/linked-in-logo-of-two-letters-svgrepo-com.svg'; 
import { ReactComponent as InstagramIcon } from '../svgs/instagram.svg';  // Add this import
import ThemeButton from "./themeButton";

const SocialLinks = () =>
{
    return (
        <div className="social-container">
            <ThemeButton />
            <a href = "https://www.Instagram.com/calvin.syy" target="_blank"><InstagramIcon className = "svg"/></a> {/* Instagram icon */}
            <a href = "https://www.github.com/calvinsyy" target="_blank"><GithubIcon className = "svg"/></a>
            <a href = "https://www.linkedin.com/in/calvinsze" target="_blank"><LinkedInIcon className = "svg"/></a>
        </div>
    );
}

export default SocialLinks;
