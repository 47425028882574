import "../styles/home.css";
import { Link } from 'react-router-dom';

const Home = () =>
{

    return(
        <div id = "home">
            <a href = "https://drive.google.com/file/d/1HzKG6mDv-zPJ65Me-lkp5GwTiWDRc73p/view?usp=sharing" target="_blank" ><h1 id = "name">CALVIN SZE</h1></a>
            <p>ART & PHOTOGRAPHY PORTFOLIO<span className="blinking">_</span></p>
            <Link to="/contact"><button id = "contact-me">Contact me</button></Link>
        </div>
    );
}

export default Home;