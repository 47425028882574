import "../styles/arts.css"; // Keep the styles linked
import { useState, useEffect } from "react"; // Using state to manage photos

const ArtsPage = () => {
    const [photos, setPhotos] = useState([]); // State for storing the photos
    const [isModalOpen, setIsModalOpen] = useState(false); // State for managing modal visibility
    const [selectedPhoto, setSelectedPhoto] = useState(null); // State for the selected photo to display in modal

    // Fetch photos (static example for now, you can replace this with actual data)
    useEffect(() => {
        const fetchedPhotos = [
            { id: 1, imageUrl: "/Arts/IMG001.jpg" }, 
            { id: 2, imageUrl: "/Arts/IMG002.jpg" }, 
            { id: 3, imageUrl: "/Arts/IMG003.jpg" }, 
            { id: 4, imageUrl: "/Arts/IMG004.jpg" }, 
            { id: 5, imageUrl: "/Arts/IMG005.jpg" }, 
            { id: 6, imageUrl: "/Arts/IMG006.jpg" }, 
            { id: 7, imageUrl: "/Arts/IMG007.jpg" }, 
            { id: 8, imageUrl: "/Arts/IMG008.jpg" },
            { id: 9, imageUrl: "/Arts/IMG009.jpg" },
            { id: 10, imageUrl: "/Arts/IMG010.jpg" },
            { id: 11, imageUrl: "/Arts/IMG011.jpg" },
            { id: 12, imageUrl: "/Arts/IMG012.jpg" },
        ];

        setPhotos(fetchedPhotos); // Set the fetched photos in state
    }, []); // Empty dependency array ensures this runs only once on mount

    // Function to open modal with selected photo
    const openModal = (photo) => {
        setSelectedPhoto(photo);
        setIsModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedPhoto(null);
    };

    return (
        <div id="photo-page-container">
            <div id="photo-container">
                {photos.length > 0 ? (
                    photos.map((photo) => (
                        <div key={photo.id} className="photo-card" onClick={() => openModal(photo)}>
                            <img src={photo.imageUrl} alt={`photo-${photo.id}`} className="photo-image" />
                        </div>
                    ))
                ) : (
                    <p>Loading photos...</p>
                )}
            </div>

            {/* Modal for viewing the enlarged photo */}
            {isModalOpen && selectedPhoto && (
                <div className="modal" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <img 
                            src={selectedPhoto.imageUrl} 
                            alt={`photo-${selectedPhoto.id}`} 
                            className="modal-image"
                        />
                        {/* Close button */}
                        <button className="close-btn" onClick={closeModal}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ArtsPage;
